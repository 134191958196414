.responsive-video {
  &.video-padding-10 {
      padding-top: 10px;
    }

  &.video-padding-20{
    padding-top: 20px;
  }

  &.video-padding-30{
    padding-top: 30px;
  }

  iframe {
    @media (max-width: $screen-md-max) {
      width: 375px;
      height: 250px;
    }

    @media (max-width: $screen-sm-max) {
      width: 340px;
      height: 200px;
    }

    @media (max-width: $screen-xs-max) {
      width: 395px;
      height: 200px;
    }

    @media (max-width: 375px) {
      width: 345px;
      height: 200px;
    }

    @media (max-width: 320px) {
      width: 290px;
      height: 200px;
    }
  }
}
