//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: "open-sans", sans-serif;
$font-family-serif: "museo-slab", serif;
$font-size-base: 18px;
$font-size-small: 16px;
$font-size-h1: 27px;
$font-size-h2: 27px;
$font-size-h3: 20px;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$line-height-base: 1.55555556;
$headings-font-family: $font-family-serif;
$headings-line-height: 1.40740741;
$headings-font-weight: $font-weight-normal;
$letter-spacing: 0.5px;

// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-blue: #007ac2;
$color-light-blue: #009ddf;
$color-gray: #3c3c3b;
$color-light-gray: #7f7f7f;
$color-soft-gray: #bfbfbf;
$brand-primary: $color-blue;
$brand-secondary: $color-gray;
$headings-color: $color-blue;
$text-color: $color-gray;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: transparentize($color-white, 0.7);
$quote-bg: #d5ecfa;

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: #99c5e8;
$well-color: $brand-primary;
$input-border-focus: $brand-primary;
