.breadcrumb {
  display: block;
  padding-top: 15px;
}

ul.breadcrumb-list {
  padding-left: 0;
  margin: 0;
  list-style: none;

  > li {
    display: inline-block;
    font-weight: $font-weight-light;
		font-size: $font-size-small - 2px;

		@media (max-width: $screen-sm) {
			font-size: 12px;
		}

    &.current {
      color: $color-light-gray;
    }

    + li:before {
      content: ">";
      padding: 0 4px;
      color: $color-light-gray;
    }

    a {
      color: $color-light-gray;
    }
  }
}
