.neos-nodetypes-text {
  &.color-primary {
    color: $brand-primary;
  }

  &.no-margin-top {
    h1, h2, h3 {
      margin-top: 0;
    }
  }

  &.letterspacing {
    letter-spacing: 1px;
  }

  &.btn-blue-primary, &.btn-blue-light {
	  a {
		  background-color: $brand-primary;
		  color: white;
		  text-decoration: unset !important;
		  padding: 15px 34px;
			position: relative;
	  }
	}

	&.btn-blue-light {
		a {
			background-color: #009DDF;
		}
	}

	&.btn-with-arrow {
		a {
			padding: 15px 50px 15px 34px;
			position: relative;

			&:after {
				content: '';
				background-image: url('../Images/layout/pfeil-white.svg');
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 51%;
				right: 30px;
				transform: translateY(-50%);
				display: inline-block;
				width: 12px;
				height: 10px;
				transition: 0.3s;
			}

			&:hover {
				&::after {
					right: 25px;
					transition: all 0.3s ease;

					@media (max-width: $screen-md) {
						transition: none;
						right: -18px;
					}
				}
			}
		}
	}
}

.neos-nodetypes-headline {
	display: inline-block;
}

.neos-nodetypes-image {
  &.team-member {
    margin: 0 0 ($grid-gutter-width / 2);

    @media (min-width: $screen-sm) {
      margin: 0 0 ($grid-gutter-width);
    }

    figure {
      position: relative;

      figcaption {
        color: $color-white;
        text-align: center;

        div {
          background-color: rgba($color: #007ac2, $alpha: 0.85);
          padding: 10px;
        }

        p {
          font-size: $font-size-small + 2px;
          letter-spacing: $letter-spacing;
          font-weight: $font-weight-light;
		  margin-bottom: 20px;

		  a {
			  color: white;
			  text-decoration: none;

			  &:hover {
				color: #151515;
			  }
		  }
        }

        p  {
			&:first-child {
				text-transform: uppercase;
			}

			&:nth-child(2) {
				position: relative;
				font-size: $font-size-small;
				text-transform: none;
				margin-bottom: 25px;

				@media (max-width: $screen-sm-max) {
					margin-bottom: 20px;
				  }

				&:before {
					position: absolute;
					top: -10px;
					left: 0;
					right: 0;
					height: 1px;
					display: block;
					width: 50px;
					background: white;
					content: '';
					margin: 0 auto;
				}
			}
		}
      }

        &:hover figcaption {
          @include transition(.7s);
          opacity:1;
        }

        figcaption {
          position: absolute;
          opacity:0;
          bottom: 0;
          right: 0;
		  z-index: 501;
		  width: 100%;
		  height: 100%;

          div {
            width: 100%;
            height: 100%;
			padding-top: 21px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-direction: column;
		  }
        }
    }
  }

  &.padding-image {
    padding-top: 30px;
  }
}

@media (min-width: $screen-md) {
  .neos-nodetypes-textwithimage {
    div {
      width: 58.33333%;
      padding: 0 15px 0 0;

      a {
        font-size: $font-size-small;
        text-transform: uppercase;
        letter-spacing: $letter-spacing;

        &:link, &:active {
          text-decoration: underline;
        }
      }
    }

    .neos-alignment-right {
      float: right;
      width: 41.66667%;
      margin: $line-height-computed 0 ($line-height-computed / 2);
      padding: 0 0 0 15px;
    }

    .neos-alignment-left {
    	float: left;
      width: 41.66667%;
      margin: $line-height-computed 0 ($line-height-computed / 2);
      padding: 0 15px 0 0;
    }

    > div {
      width: 58.33333%;
      float: right;
      padding: 0 0 0 15px;
    }
  }
}
