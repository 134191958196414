.callout {
  margin: 0 auto !important;
  border-top: 1px solid $color-soft-gray;
  border-bottom: 1px solid $color-soft-gray;

  .item {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $brand-secondary;

    @media (min-width: $screen-lg) {
      height: 347px;
    }

    @media (max-width: $screen-md-max) {
      height: 360px;
    }

    @media (max-width: $screen-sm-max) {
      height: 300px;
    }

    @media (max-width: $screen-xs-max) {
	  height: 200px;
	  background-position: top;
    }
  }

  &.full {
    .item {
      @media (min-width: $screen-lg) {
        height: 523px;
      }

      @media (max-width: $screen-md-max) {
        height: 360px;
      }

      @media (max-width: $screen-sm-max) {
        height: 395px;
      }

      @media (max-width: $screen-xs-max) {
		height: 408px;
		background-position: top;
      }
    }
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  // display: none;
  position: absolute;
  // padding: 20px;
  // background-color: $callout-caption-bgcolor;
  color: $brand-primary;

  @media (min-width: $screen-sm-min) {
    position: relative;
    display: block;
    left: 0;
    top: 5%;
    width: 585px;
  }

  h1 {
    font-size: 34px;
    line-height: 42px;

      @media (max-width: $screen-md-max) {
        font-size: 27px;
        line-height: 35px;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 24px;
        line-height: 31px;
      }

    & strong {
      font-weight: 300;
      color: $color-black;
    }
  }

  p {
    color: $color-gray;
    margin-bottom: 30px;

   a {
     background-color: $brand-primary;
     text-align: center;
     text-transform: uppercase;
     letter-spacing: $letter-spacing;
     border: none;
     padding: 15px 80px;
     color: $color-white;
     font-size: $font-size-base;
     font-weight: $font-weight-normal;

      &:link, &:active {
        text-decoration: none;
      }

      @media (max-width: $screen-md-max) {
       font-size: 15px;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 13px;
      }

      @media (max-width: 320px) {
       font-size: 13px;
      }
    }
  }
}
