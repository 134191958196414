.jobs--wrapper {
	@extend .container;
	margin-bottom: 18px;
	padding-bottom: 15px;
	border-bottom: 1px solid #c0dcf1;

	@media(min-width: $screen-md) {
			margin-bottom: 30px;
			padding-bottom: 45px;
	}

	@media(max-width: $screen-sm) {
		padding-bottom: 35px;
	}

	li {
		list-style: none;
		position: relative;
		width: fit-content;
		display: inline-block;
		font-weight: 400;
		margin-bottom: 18px;

			&::after {
				content: '';
				position: absolute;
				top: 60%;
				transform: translateY(-50%);
				right: -18px;
				background-image: url("../Images/layout/pfeil-grey.svg");
				background-repeat: no-repeat;
				background-size: contain;
				width: 14px;
				height: 14px;
				transition: all 0.3s;
		}
	}

	a {
		color: inherit;

		&:hover {
			color: #004a76;

			li {
				&::after {
					right: -25px;
					transition: all 0.3s ease;

					@media (max-width: $screen-md) {
						transition: none;
						right: -18px;
					}
				}
			}
		}
	}
}
