.grid-content {
  @extend .container;

  @media(min-width: $screen-md) {
    margin-bottom: 32px;
  }

  @media(max-width: $screen-sm) {
    margin-bottom: 30px;
  }

  &.divider {
    margin-bottom: 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c0dcf1;

    @media(min-width: $screen-md) {
      margin-bottom: 30px;
      padding-bottom: 45px;
	}

	@media(max-width: $screen-sm) {
		padding-bottom: 35px;
	  }
  }

  &.quote-section {
    width: 100%;
    background-color: $quote-bg;

    > .grid-content-container {
      @extend .container;
    }
  }
}

.grid-content-col {
  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }

  &.padding-top {
    padding-top: 30px;
  }

  &.col-8,  &.col-7 {
	@media (max-width: $screen-md) {
		@include make-sm-column(12);
	  }
  }

  &.col-5 {
	z-index: 999;
	@media (max-width: $screen-md) {
		@include make-sm-column(8);
		@include make-xs-column(12);
	  }
  }

&.hide-mobile {
	@media (max-width: $screen-md) {
		display: none;
	}
}

&.margin-top {
	margin-top: 47px;

	@media (max-width: $screen-md) {
		margin-top: 20px;
	}
}

  &.product-icon-home-col {
    @media (min-width: $screen-lg) {
      margin-top: $line-height-computed;
      padding-left: 82px;
	}

    .neos-nodetypes-image.product-icon {
      float: left;
      width: 50%;
      padding-bottom: 30px;

      @media (max-width: $screen-md-max) {
        &:nth-child(2n+1) {
          padding-right: 15px;
        }

        &:nth-child(2n) {
          padding-left: 15px;
        }
      }

      @media (min-width: $screen-lg) {
        width: 195px;
        padding: 0 0 30px 30px;
      }
    }
  }
}
