$footer-nav-min-height: 250px;

@mixin border-left {
  border-left: 1px solid #92bee5;
}

.footer {
  padding: 20px 0;
  background-color: $brand-primary;
  color: $color-white;
  margin-top: 55px;

  @media (min-width: $screen-md) {
    padding: 30px 0 40px;
  }
}

.footer-content {
  @extend .container;
}

.footer-about {
  @include make-sm-column(5);
  font-size: $font-size-small;
    @media (max-width: $screen-sm-max) {
      width: 37%;
     }

  p {
    & b {
      color: #92bee5;
    }

    a {
      color: $color-white;

      &:hover {
        color: darken($text-color, 15%);
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    margin-left: 20px;
    }
    @media (max-width: $screen-xs-max) {
        width: auto;
     }
}

.footer-nav {
  @include make-sm-column(2);
  @include border-left;
  min-height: $footer-nav-min-height;

  @media (max-width: $screen-xs-max) {
    min-height: auto;
    }

  a {
    color: $color-white;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  @media (max-width: $screen-sm-max) {
    width: 23%;
   }

   @media (max-width: $screen-xs-max) {
		@include make-xs-column(6);
    }
}

.footer-nav-list,
.footer-meta-nav-list {
  padding: 10px 15px;
  margin: 0;
  list-style-type: none;

  li {
    padding: 5px 0;
  }

  @media (max-width: $screen-sm-max) {
	margin-top: 10px;
	padding-top: 0;
	min-height: 185px;

    li {
	  padding: 0;
	  padding-bottom: 10px;
	  display: block;

	  &:last-child {
		  padding-bottom: 0;
	  }
    }
  }
}

.footer-nav-list {
	@media (max-width: $screen-sm-max) {
		padding-left: 20px;
	}
}

.footer-meta-nav {
  @extend .footer-nav;

  @media (max-width: $screen-sm-max) {
    width: 21%;
   }

   @media (max-width: $screen-xs-max) {
		@include make-xs-column(6);
		min-height: 212px;
    }
}

.footer-banner {
  @include make-xs-column(12);
  @include make-md-column(2);
  @include border-left;
  min-height: $footer-nav-min-height;

	@media (max-width: $screen-xs-max) {
		min-height: 130px;
		margin-top: 15px;
	}

  > img {
    width: 75px;
    margin: 15px 15px 10px 15px;
    vertical-align: top;
    background-color: $color-white;

    @media (min-width: $screen-md) {}

    @media (min-width: $screen-lg) {
      width: 100%;
    }
  }

  @media (max-width: $screen-sm-max) {
   float: right;
    width: 19%;
   }
   @media (max-width: $screen-xs-max) {
       width: auto;
       float: left;
    }
}
