.teaser {
  @media (max-width: $screen-sm-max) {
    margin-bottom: 35px;
  }

  figure {
    margin: 0 0 ($line-height-computed / 2);
  }

  h3.teaser-headline {
    margin-bottom: 10px;
    font-weight: $font-weight-normal;
  }

  .teaser-description {
    font-weight: $font-weight-light;
    margin: 0 0 ($line-height-computed / 2);
  }

  .teaser-more {
    margin: 0 0 ($line-height-computed / 2);

    a {
      text-transform: uppercase;
      letter-spacing: $letter-spacing;
      text-decoration: underline;
      font-size: $font-size-small;

      @media(min-width: $screen-md){
        margin: 0 0 ($line-height-computed / 2);
      }
    }
  }
}
