body {
  font-weight: $font-weight-light;

  > .content {
    margin: 15px auto 0;
    min-height: calc(40vh);

    img {
      @extend .img-responsive;
    }

    ul {
	  padding-left: 20px;
	  list-style: none;

	  li::before {
			content: "\2022";
			// color: $color-orange;
			font-weight: bold;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-left: -19px;
        }
    }

    table {
      @extend .table;
    }
  }

  @media (max-width: $screen-md-max) {
    font-size: 16px;
  }

  @media (max-width: 320px) {
    font-size: 15px;
  }

}

h1 {
  margin-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    font-size: 24px;
    line-height: 31px;
  }
}

h2 {
  margin-bottom: 20px;
  @media (max-width: $screen-xs-max) {
    font-size: 22px;
  }
}

h3 {
  font-family: $font-family-sans-serif;
  line-height: 1.4;
  font-weight: $font-weight-normal;
  color: $color-gray;

  @media (max-width: $screen-md-max) {
    font-size: 18px;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 16px;
  }
}

.neos-contentcollection {
  @include clearfix;

  a {
    &:link, &:active {
      text-decoration: underline;
    }
  }

//   ul {
//     &.neos-list-disc {
//       list-style-type: disc;
//     }

//     &.neos-list-circle {
//       list-style-type: circle;
//     }

//     &.neos-list-square {
//       list-style-type: square;
//     }
//   }
}
