.contact--wrapper {
	position: fixed;
	top: 35%;
	right: 0;
	display: block;
	z-index: 9999;

	@media (max-width: $screen-sm-max) {
		top: auto;
		bottom: 0;
	  }
}

.contact-icon {
  width: 65px;
  height: 65px;
  background: transparent url("../Images/layout/cadkon-icon-farbig-kontakt.svg") center center no-repeat;
  margin-bottom: 12px;

  @media (max-width: $screen-sm-max) {
	width: 55px;
	height: 55px;
  }

  @media (min-width: $screen-lg) {
    top: 55%;
  }

  .contact-icon-link {
    width: 65px;
    height: 65px;
    display: block;
  }
}

.phone-contact--wrapper {
	background-color: #009DDF;
	width: 65px;
	height: 65px;
	background-image: url("../Images/layout/call-24px-white.svg");
	background-repeat: no-repeat;
	background-size: 70% 70%;
	background-position: center;

	@media (max-width: $screen-sm-max) {
		top: auto;
		bottom: 0;
		width: 55px;
		height: 55px;
	  }

	a {
		width: 65px;
		height: 65px;
		display: block;
	}
}
