$header-logo-height-md: 100px;
$header-logo-height-xs: $header-logo-height-md/2;

.header {
  @extend .container-fluid;
  padding-top: 10px;
  padding-bottom: 10px;

  .header-content {
    @extend .container;
  }

  @media (min-width: $screen-lg) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  @media (max-width: 1024px) {
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding-bottom: 10px;
  }

  &.fixed {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
	animation: smoothScroll 0.3s forwards;
	border-bottom: 1px solid #bfbfbf;

	@media (min-width: 1024px) {
		.header-nav-sub-content {
			display: none
		}
	  }

	@media (max-width: 768px) {
		.header-nav-list {
			height: 500px;
			overflow-y: scroll;
		}

		.header-logo {
			z-index: 0;
		}
	}
  }
}

.header-logo {
  @include make-md-column(3);
  z-index: 500;

  @media (max-width: $screen-sm-max) {
    // Align logo and toggle
    position: absolute !important;
  }

  img {
    height: $header-logo-height-xs;

    @media (min-width: $screen-md) {
      height: $header-logo-height-md;
    }
  }
}

.header-nav {
  position: relative;

  @media (min-width: $screen-md) {
    clear: right;
    padding-left: 15px;
    padding-right: 15px;
    top: 40px;
  }

  a {
    color: $color-black;
      @media (max-width: $screen-md-max) {
        font-size: 15px;
      }

    &:hover {
      color: $color-blue;
    }
  }

  li {
    &.active > a,
    &.current > a {
      font-weight: $font-weight-normal;
      color: $color-blue;
    }
  }
}

.meta-nav {
  @extend .visible-lg-block;
  @extend .visible-md-block;
  @include make-md-column(7);
  top: 30px;

  @media (min-width: $screen-md) {
    float: right;
  }

  @media (max-width: $screen-sm) {
	// display: block !important;
	// position: absolute;
	// top: -43px;
	// right: 50px;
  }

  @media (max-width: 320px) {
	right: 46px;
  }
}

.header-nav-list,
.header-nav-sub,
.meta-nav-list {
  list-style-type: none;
}

.header-nav-list {
  padding: 0;
  margin-bottom: 0;

  // Add extra padding to prevent list from overlaying logo
  @media (max-width: $screen-sm-max) {
    padding-top: $header-logo-height-xs+20px;
  }

  // Add extra padding to prevent list from overlaying logo
  @media (max-width: $screen-sm-max) {
    margin-bottom: 0;
    padding: $header-logo-height-xs+20px 15px 0;

    > li {
      padding: 15px 0 14px;
	  border-bottom: 1px solid $color-soft-gray;
	  background-color: white;
    }

    li:last-child {
      border-bottom: none;
    }
  }

  @media (min-width: $screen-md) {
    text-align: right;
    font-weight: $font-weight-normal;

    li {
      display: inline-block;
    }

    > li + li:before {
      display: inline-block;
      content: '';
      padding: 0 15px 0 0;
    }
  }

  @media (min-width: $screen-lg) {
    > li + li:before {
      padding: 0 25px 0 0;
    }
  }
}

.meta-nav-list {
  @extend .header-nav-list;
  font-size: 14px;
  font-weight: $font-weight-light;
}

@media (min-width: $screen-md) {
  .header-nav-sub {
    display: none;
    position: absolute;
    top: 63px;
    background-color: $color-white;
    z-index: 200;
    height: auto;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  li.current .header-nav-sub,
  li.active .header-nav-sub {
    display: block;
  }
}

@media (max-width: 1199px) {
	.header-nav-sub {
		top: 38px;
	}
}

@media (max-width: 1024px) {
	.header-nav-sub {
		top: 63px;
	}
}

.header-nav-sub-content {
  @extend .container;

  @media (min-width: $screen-md) {
    height: 100%;
    padding: 30px 15px;
  }
}

.header-nav-sub-description {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
    float: left;
    width: 60%;
    min-height: 1px;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    text-align: left;
    padding: 12px 50px 0 0;
  }

  @media (min-width: $screen-lg) {
    width: 60%;
  }
}

.header-nav-sub-list {
  list-style: none;
  text-align: left;
  border-left: 1px solid $color-soft-gray;

  li {
    padding: 4px 0;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
  }

  @media (max-width: $screen-sm-max) {
    width: 100%;
    margin: 5px 0 0;
    padding-left: 15px;

    > li {
      padding: 4px 0;
    }
  }

  @media (min-width: $screen-md) {
    float: left;
    padding: 8px 32px;
    width: 10%;

    li {
      padding: 4px 0;
      display: block;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;

      a {
        padding: 6px 0;
      }
    }
  }

  @media (min-width: $screen-lg) {
    width: 20%;
  }
}

.header-nav-toggle {
  color: $brand-primary;

  button {
    border: none;
    margin-top: 30px;
    padding: 0;
  }

  span {
    background-color: $color-black;

    &.icon-bar {
      width: 35px;
      height: 2px;
      border-radius: 4px;

      + .icon-bar {
        margin-top: 6px;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content.collapse {
    display: block;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}

.header-nav-sub-icon {
  @extend .visible-lg-block;
  @extend .visible-md-block;

  float: right;
  width: 165px;
  height: 165px;
  padding: 0;
  margin: 10px 0 0;

  @mixin icon {
    background-size: 165px 165px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.icon-engineering {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-farbig-maschinenbau.svg");
  }

  &.icon-assembly {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-farbig-fertigung.svg");
  }

  &.icon-construction {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-farbig-konstruktion.svg");
  }

  &.icon-control {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-farbig-steuerung.svg");
  }

  &.icon-company {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-farbig-unternehmen.svg");
  }
}
