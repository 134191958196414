.contact-section {
  @media (min-width: $screen-md) {
    padding-bottom: 55px;
  }
}

.contact-intro {
  @include make-lg-column(8);
}

.contact-content {
  padding: 0;

  @media (min-width: $screen-md) {
    padding: 35px 0;
  }
}

.content-left, .content-right {
  @include make-lg-column(6);
  padding: 0;
}

.contact-column {
  @include make-sm-column(12);
}

.contact-firstname,
.contact-lastname {
  @include make-sm-column(6);
}

@media (min-width: $screen-sm) {
  .contact-firstname {
    padding-right: 0;
  }
}

.contact-privacy {
	display: inline-block;
	@include make-lg-column(12);
	margin-bottom: 10px;

	input {
	  width: 20px;
	  margin-top: 6px;

	  @media (min-width: $screen-md) {
		padding-bottom: 55px;
	  }
	}

	.contact-privacy-description {
		display: contents
	}
  }

.contact-submit {
  @include make-md-column(4);
  margin: ($line-height-computed / 2) 0 0;

  input[type="submit"] {
    background-color: $brand-primary;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: $letter-spacing * 2;
    border: none;
    padding: 12px;
    color: $color-white;
    font-size: $font-size-small;
	font-weight: $font-weight-normal;
	border-radius: unset;
  }
}

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}

.form-control {
  border-radius: 0;
  border: none;
  color: $text-color;
  background-color: lighten($color-light-gray, 40%);
  @include box-shadow(none);
  font-weight: $font-weight-light;
}

textarea.form-control {
  height: 156px;
}

.btn-form {
  width: 100%;
}
