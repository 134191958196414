.well {
  position: relative;
  box-shadow: none;
  padding: 30px 50px 20px 30px;

  p {
    font-family: $font-family-serif;
    font-size: 28px;
    line-height: 38px;
    font-style: italic;
    color: $well-color;

    @media (max-width: $screen-md-max) {
      font-size: 25px;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 23px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  @mixin icon {
    background-size: 65px;
    background-position: 30px 30px;
    background-repeat: no-repeat;
  }

  &.icon-engineering {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-blau-maschinenbau.svg");
  }

  &.icon-assembly {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-blau-fertigung.svg");
  }

  &.icon-construction {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-blau-konstruktion.svg");
  }

  &.icon-control {
    @include icon;
    background-image: url("../Images/layout/cadkon-icon-blau-steuerung.svg");
  }

  &.icon-engineering, &.icon-assembly, &.icon-construction, &.icon-control {
	margin: $line-height-computed 0;

	@media(max-width: $screen-sm) {
		margin-bottom: 14px;
	  }


    .neos-contentcollection {
      margin-top: 95px;
    }
  }

  .more {

    > a {
      @media (min-width: $screen-md) {
        line-height: $line-height-computed * 2;
      }

      &:after {
        content: "\e080"; /* glyphicon-chevron-right */
        font-family: "Glyphicons Halflings";
        font-size: $font-size-small;
        line-height: $font-size-small + 3px;
        padding-left: 5px;

        @media (max-width: $screen-sm-max) {
          right: 20px;
        }
      }
    }
  }
}
