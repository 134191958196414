.languages {
  @extend .visible-lg-block;
  @extend .visible-md-block;
  @include make-md-column(2);
  top: 30px;

  @media (max-width: $screen-sm-max) {
  }

  @media (max-width: $screen-sm) {
	display: block !important;
	position: absolute;
	top: 38px;
	right: 60px;
	z-index: 99;
  }

  @media (min-width: $screen-md-min) {
    float: right;
    max-width: 105px;
  }


  @media (min-width: $screen-lg-min) {
  }
}

.languages-list {
  @extend .list-inline;
  text-align: right;
  font-size: 14px;

  li {
    margin: 0;
    padding: 0;
  }

  a {
    @media (min-width: $screen-sm-min) {
      color: $color-blue;
    }
  }

  .current a {
    font-weight: 300;
  }

  .dropdown-toggle {
    &:after {
      display: inline-block;
      content: ' ';
      background-image: url('../Images/layout/down.svg');
      background-size: 16px 16px;
      background-position: 4px 3px;
      background-repeat: no-repeat;
      height: 16px;
      width: 20px;
    }
  }
}
