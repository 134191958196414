.quote {
  @media (max-width: $screen-sm) {
    margin-right: -15px;
    margin-left: -15px;
  }

  .quote-content {
    padding: 50px 0 50px;
  }
  .quote-passage {
    font-family: $font-family-serif;
    font-size: 28px;
    line-height: 38px;
    font-style: italic;
    color: $brand-primary;
    padding: 0 0 10px;
    margin: 0;

    @media (max-width: $screen-sm-max) {
      font-size: 27px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 25px;
    }
  }

  .quote-author {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $color-gray;

    @media (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }
}
